.portfolio__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px dotted black;
    padding-bottom: 2rem;
}

.portfolio__header-left {
    width: 30%;
}

.portfolio__header-right {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio__content {
    display: flex;
    flex-direction: column;
}

.portfolio__card {
    margin-top: 4rem;
    gap: 12%;
}

.portfolio__item-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30rem;
}

.portfolio__image {
    height: fit-content;
    max-height: 30rem;
    overflow: hidden;
    border: 1rem solid var(--color-white);
    transition: var(--transition);
}

.portfolio__image:hover {
    border-width: 0;
}

.portfolio__card {
    text-align: center;
    width: 100%;
    min-height: 32rem;
}

.portfolio__card h3 {
    margin-top: 2rem;
}

.portfolio__card:hover {
    cursor: pointer;
}

.portfolio__entry:nth-child(even) .portfolio__image {
    float: left;
    width: 35%;
}

.portfolio__entry:nth-child(odd) .portfolio__image {
    float: right;
    width: 35%;
}

.portfolio__entry:nth-child(even) .portfolio__about {
    float: left;
    width: 53%;
}

.portfolio__entry:nth-child(odd) .portfolio__about {
    float: right;
    width: 53%;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {

    .portfolio__card {
        display: flex;
        flex-direction: column;
    }

    .portfolio__entry {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .portfolio__entry .portfolio__image {
        border-top-left-radius: 10rem;
        border-top-right-radius: 10rem;
        align-self: center;
    }

    .portfolio__entry:nth-child(even) .portfolio__image {
        float: none;
        width: 20rem;
    }

    .portfolio__entry:nth-child(odd) .portfolio__image {
        float: none;
        width: 20rem;
    }

    .portfolio__entry:nth-child(even) .portfolio__about {
        float: none;
        width: 20rem;
    }

    .portfolio__entry:nth-child(odd) .portfolio__about {
        float: none;
        width: 20rem;
    }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .portfolio__item-about {
        height: 10rem;
    }
}