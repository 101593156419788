header {
  background-image: url("../../../assets/header-background.jpg");
  background-size: cover;
  width: auto;
  height: 10rem;
  min-height: 50rem;
  border-bottom: 10px solid black;
}

.header__image {
  width: 50rem;
  padding-top: 6rem;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  .header__image {
    width: 20rem;
  }

  header {
    height: 20rem;
    min-height: 20rem;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  .header__image {
    width: 20rem;
  }

  header {
    height: 20rem;
    min-height: 20rem;
  }
}